import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
  <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
    <div className="container">
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        Menu
        <i className="fas fa-bars ms-1"></i>
      </button>
      <div className="collapse navbar-collapse" id="navbarResponsive">
        <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
          <li className="nav-item"><a className="nav-link" href="#about">About</a></li>
          <li className="nav-item"><a className="nav-link" href="#skills">Skills</a></li>
          <li className="nav-item"><a className="nav-link" href="#projects">Projects</a></li>
          <li className="nav-item"><a className="nav-link" href="#contact">Contact</a></li>
        </ul>
      </div>
    </div>
  </nav>
  <header className="masthead">
    <div className="container">
      <div className="masthead-subheading">Welcome To Hansen Resume!</div>
      <div className="masthead-heading text-uppercase">It's Nice To Meet You</div>
      <a className="btn btn-primary btn-xl text-uppercase" href="#about">Tell Me More</a>
    </div>
  </header>
  <section className="page-section" id="about">
    <div className="container">
      <div className="text-center">
        <h2 className="section-heading text-uppercase">About Me</h2>
        <div className="timeline-image"><img className="rounded-circle img-fluid" src="/assets/img/about/hansenfull.png" alt="..." /></div>
        <h3 className="section-subheading text-muted" style={{paddingTop:"10px"}}>Hello! i'm <b>Hansen Dwi Putra, S.Kom</b>, <b>34</b> years old. <br />I had <b>more than 10 years of experience</b> in the IT field, especially in the <b>software field</b>, and <b>more than 5 years in the managerial field</b>, able to provide solutions to company needs on an ongoing basis and able to bridge the relationship between employees and management.</h3>
      </div>
      <ul className="timeline">
        <li>
          <div className="timeline-image">
            <a href="https://www.mitraplus.com/" target="_blank" rel="noreferrer"><img className="rounded-circle img-fluid" src="/assets/img/about/logomitra.png" alt="Mitraplus" /></a>
            </div>
          <div className="timeline-panel">
            <div className="timeline-heading">
              <h4>August 2010 – December 2013</h4>
              <h4 className="subheading">PT Mitrainfotek Totalsolusi</h4>
              <h4 className="subheading">Supervisor Programmer</h4>
            </div>
            <div className="timeline-body">
              <p className="text-muted">Company engaged in software development.</p>
            </div>
          </div>
        </li>
        <li className="timeline-inverted">
          <div className="timeline-image">
            <a href="https://www.summarecon.com/" target="_blank"  rel="noreferrer"><img className="rounded-circle img-fluid" src="/assets/img/about/logosummarecon.png" alt="Summarecon" /></a>
            
          </div>
          <div className="timeline-panel">
            <div className="timeline-heading">
              <h4>January 2014 - December 2015</h4>
              <h4 className="subheading">PT Summarecon Agung, Tbk</h4>
              <h4 className="subheading">Senior Programmer</h4>
            </div>
            <div className="timeline-body">
              <p className="text-muted">Company engaged in the property sector.</p>
            </div>
          </div>
        </li>
        <li>
          <div className="timeline-image">            
            <a href="https://adonai.co.id/" target="_blank"  rel="noreferrer"><img className="rounded-circle img-fluid" src="/assets/img/about/logoadonai.png" alt="Adonai" /></a>
          </div>
          <div className="timeline-panel">
            <div className="timeline-heading">
              <h4>December 2015 – Now</h4>
              <h4 className="subheading">PT. Adonai Pialang Asuransi</h4>
              <h4 className="subheading">Assistant Manager</h4>              
            </div>
            <div className="timeline-body">
              <p className="text-muted">Company engaged Insurance broker.</p>
            </div>
          </div>
        </li>
       
      </ul>
    </div>
  </section>
  <section className="page-section" id="skills">
    <div className="container">
      <div className="text-center">
        <h2 className="section-heading text-uppercase">Skills</h2>
      </div>
      <div className="row text-center">
        <div className="col-md-4">
          <span className="fa-stack fa-4x">
            <i className="fas fa-circle fa-stack-2x text-primary"></i>
            <i className="fas fa-laptop-code fa-stack-1x fa-inverse"></i>
          </span>
          <h4 className="my-3">Programming Language</h4>          
          <div className="mb-2" style={{textAlign: "left"}}>
            <span>HTML</span>
            <div className="progress">
              <div className="progress-bar bg-primary" role="progressbar" style={{width: "80%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div className="mb-2" style={{textAlign: "left"}}>
            <span>CSS</span>
            <div className="progress">
              <div className="progress-bar bg-primary" role="progressbar" style={{width: "75%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div className="mb-2" style={{textAlign: "left"}}>
            <span>PHP</span>
            <div className="progress">
              <div className="progress-bar bg-primary" role="progressbar" style={{width: "90%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div className="mb-2" style={{textAlign: "left"}}>
            <span>Javascript</span>
            <div className="progress">
              <div className="progress-bar bg-primary" role="progressbar" style={{width: "90%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div className="mb-2" style={{textAlign: "left"}}>
            <span>Dart (Flutter)</span>
            <div className="progress">
              <div className="progress-bar bg-primary" role="progressbar" style={{width: "60%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div className="mb-2" style={{textAlign: "left"}}>
            <span>Angular</span>
            <div className="progress">
              <div className="progress-bar bg-primary" role="progressbar" style={{width: "70%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div className="mb-2" style={{textAlign: "left"}}>
            <span>Visual Basic</span>
            <div className="progress">
              <div className="progress-bar bg-primary" role="progressbar" style={{width: "50%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div className="mb-2" style={{textAlign: "left"}}>
            <span>Power Builder</span>
            <div className="progress">
              <div className="progress-bar bg-primary" role="progressbar" style={{width: "90%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div className="mb-2" style={{textAlign: "left"}}>
            <span>ReactJS</span>
            <div className="progress">
              <div className="progress-bar bg-primary" role="progressbar" style={{width: "60%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div className="mb-2" style={{textAlign: "left"}}>
            <span>React Native</span>
            <div className="progress">
              <div className="progress-bar bg-primary" role="progressbar" style={{width: "30%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <span className="fa-stack fa-4x">
            <i className="fas fa-circle fa-stack-2x text-primary"></i>
            <i className="fas fa-database fa-stack-1x fa-inverse"></i>
          </span>
          <h4 className="my-3">Database</h4>
          <div className="mb-2" style={{textAlign: "left"}}>
            <span>SQL Server</span>
            <div className="progress">
              <div className="progress-bar bg-success" role="progressbar" style={{width: "90%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div className="mb-2" style={{textAlign: "left"}}>
            <span>MySQL</span>
            <div className="progress">
              <div className="progress-bar bg-success" role="progressbar" style={{width: "95%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div className="mb-2" style={{textAlign: "left"}}>
            <span>Oracle</span>
            <div className="progress">
              <div className="progress-bar bg-success" role="progressbar" style={{width: "60%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div className="mb-2" style={{textAlign: "left"}}>
            <span>MongoDB</span>
            <div className="progress">
              <div className="progress-bar bg-success" role="progressbar" style={{width: "40%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>   
        </div>
        <div className="col-md-4">
          <span className="fa-stack fa-4x">
            <i className="fas fa-circle fa-stack-2x text-primary"></i>
            <i className="fas fa-network-wired fa-stack-1x fa-inverse"></i>
          </span>
          <h4 className="my-3">Infrastructure</h4>
          <div className="mb-2" style={{textAlign: "left"}}>
            <span>Proxmox</span>
            <div className="progress">
              <div className="progress-bar bg-warning" role="progressbar" style={{width: "60%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div className="mb-2" style={{textAlign: "left"}}>
            <span>Mikrotik</span>
            <div className="progress">
              <div className="progress-bar bg-warning" role="progressbar" style={{width: "60%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="page-section bg-light" id="projects">
    <div className="container">
      <div className="text-center">
        <h2 className="section-heading text-uppercase">Projects</h2>
      </div>
      <div className="row">
        <div className="col-lg-6 col-sm-12 mb-6">          
          <div className="portfolio-item">
            <a className="portfolio-link" data-bs-toggle="modal" href="#portfolioModal1">            
                <img className="img-fluid" src="/assets/img/portfolio/p-2.png" alt="..." />              
            </a>
            <div className="portfolio-caption">
              <div className="portfolio-caption-heading">Finance and Accounting</div>
              <div className="portfolio-caption-subheading text-muted">At PT. Mitrainfotek Totalsolusi, PT. Adonai Pialang Asuransi</div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 mb-6">
          <div className="portfolio-item">
            <a className="portfolio-link" data-bs-toggle="modal" href="#portfolioModal2">            
                <img className="img-fluid" src="/assets/img/portfolio/p-1.png" alt="..." />
            </a>
            <div className="portfolio-caption">
              <div className="portfolio-caption-heading">Insurance System</div>
              <div className="portfolio-caption-subheading text-muted">At PT. Mitrainfotek Totalsolusi, PT. Adonai Pialang Asuransi</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{paddingTop: "20px"}}>
        <div className="col-lg-6 col-sm-12 mb-6">
          <div className="portfolio-item">
            <a className="portfolio-link" data-bs-toggle="modal" href="#portfolioModal3">
              <img className="img-fluid" src="/assets/img/portfolio/p-4.png" alt="..." />
            </a>
            <div className="portfolio-caption">
              <div className="portfolio-caption-heading">Asset Management</div>
              <div className="portfolio-caption-subheading text-muted">At PT. Summarecon Agung, Tbk</div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 mb-6 mb-lg-0">
          <div className="portfolio-item">
            <a className="portfolio-link" data-bs-toggle="modal" href="#portfolioModal4">
              <img className="img-fluid" src="/assets/img/portfolio/p-3.png" alt="..." />
            </a>
            <div className="portfolio-caption">
              <div className="portfolio-caption-heading">Human Resource System</div>
              <div className="portfolio-caption-subheading text-muted">At PT. Summarecon Agung, Tbk, PT. Adonai Pialang Asuransi</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="page-section" id="contact">
    <div className="container">
      <div className="text-center">
        <h2 className="section-heading text-uppercase">Contact Me</h2>
        <h6 className="text-white">
          
            Email : <a href="mailto:hansendputra@gmail.com" style={{color: "white"}} target="_blank" rel="noreferrer">hansendputra@gmail.com</a>
            <br />
            Phone / WA : <a href="tel:+6287888284959"  style={{color: "white"}} target="_blank"  rel="noreferrer">0878-8828-4959</a>
          
        </h6>
        <a className="btn btn-social btn-primary mx-2"  href="https://www.linkedin.com/in/hansen-dwi-putra-74890448" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a>
      <a className="btn btn-social btn-success mx-2"  href="https://wa.me/6287888284959" target="_blank" rel="noreferrer"><i className="fab fa-whatsapp"></i></a>
      <a className="btn btn-social btn-danger mx-2"  href="tel:+6287888284959" target="_blank" rel="noreferrer"><i className="fas fa-phone"></i></a>
      <a className="btn btn-social btn-warning mx-2"  href="mailto:hansendputra@gmail.com" target="_blank" rel="noreferrer"><i className="fas fa-envelope-square"></i></a>
        
      </div>
      
    </div>
  </section>
  <footer className="footer py-4">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-12">Copyright &copy; Hansen Dwi Putra</div>
      </div>
    </div>
  </footer>
  <div className="portfolio-modal modal fade" id="portfolioModal1" tabIndex="-1" role="dialog" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="close-modal" data-bs-dismiss="modal"><img src="/assets/img/close-icon.svg" alt="Close modal" /></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="modal-body">
                <h2 className="text-uppercase">Finance and Accounting</h2>
                <img className="img-fluid d-block mx-auto" src="assets/img/portfolio/p-2.png" alt="..." />
                
                <button className="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                  <i className="fas fa-times me-1"></i>
                  Close Project
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="portfolio-modal modal fade" id="portfolioModal2" tabIndex="-1" role="dialog" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="close-modal" data-bs-dismiss="modal"><img src="/assets/img/close-icon.svg" alt="Close modal" /></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="modal-body">
                <h2 className="text-uppercase">Insurance System</h2>
                <img className="img-fluid d-block mx-auto" src="assets/img/portfolio/p-1.png" alt="..." />
                
                <button className="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                  <i className="fas fa-times me-1"></i>
                  Close Project
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="portfolio-modal modal fade" id="portfolioModal3" tabIndex="-1" role="dialog" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="close-modal" data-bs-dismiss="modal"><img src="/assets/img/close-icon.svg" alt="Close modal" /></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="modal-body">
                <h2 className="text-uppercase">Asset Management</h2>
                <img className="img-fluid d-block mx-auto" src="/assets/img/portfolio/p-4.png" alt="..." />                
                <button className="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                  <i className="fas fa-times me-1"></i>
                  Close Project
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="portfolio-modal modal fade" id="portfolioModal4" tabIndex="-1" role="dialog" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="close-modal" data-bs-dismiss="modal"><img src="/assets/img/close-icon.svg" alt="Close modal" /></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="modal-body">
                <h2 className="text-uppercase">Human Resource System</h2>
                
                <img className="img-fluid d-block mx-auto" src="/assets/img/portfolio/p-3.png" alt="..." />
                <button className="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                  <i className="fas fa-times me-1"></i>
                  Close Project
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  

    </div>
  );
}

export default App;
